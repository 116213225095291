import logo from './logo.svg';
import './App.css';
import LogIn from './components/LogIn';
import { Route, Routes } from 'react-router';
import Home from './components/Home';
import Navbar from './components/Navbar';
import { PrivateRoute } from './components/PrivateRouts';
import Add from './components/Add';
import List from './components/List';

function App() {
  return (
    <div className="App">
      <Navbar/>
      
      <Routes>
        <Route path='/' element={<LogIn/>}/>
        <Route path='/home' element={<PrivateRoute><Home/></PrivateRoute>}/>
        <Route path='/add' element={<PrivateRoute><Add/></PrivateRoute>}/>
        <Route path='/list' element={<PrivateRoute><List/></PrivateRoute>}/>


       
      </Routes>
    </div>
  );
}

export default App;
