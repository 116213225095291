import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
    let token = sessionStorage.getItem("Api_token");
  
    if (!token) {
      return <Navigate to="/" />;
    }
  
    return <>{children}</>;
  };