import React from 'react' 
import {useNavigate} from 'react-router-dom'
import { FaEdit, FaListAlt,FaPlus } from 'react-icons/fa';
import { MdDeleteForever, MdDeleteSweep } from 'react-icons/md';
import { AiFillFolderAdd } from 'react-icons/ai';

import './style.css'

const home = [
    {
        title :'Categories', 
        url:'c ategories'
    },
    // {
    //     title :'Annotations', 
    //     url:'annotations'
    // },

    // {
    //     title :'Categorization AIs', 
    //     url:'categorization'
    // },

    // {
    //     title :'Documents', 
    //     url:'documents'
    // },

    // {
    //     title :'Extraction AIs', 
    //     url:'extraction-ais'
    // },

    // {
    //     title :'Label sets', 
    //     url:'label-sets'
    // },

    // {
    //     title :'Labels', 
    //     url:'labels'
    // },
    // {
    //     title :'Members', 
    //     url:'members'
    // },
    // {
    //     title :'Projects', 
    //     url:'projects'
    // },


]


const Home = () => { 
    const token = sessionStorage.getItem('Api_token')
    console.log(token);

    const navigate = useNavigate()

  return (
    <div> 
        <div style={{height:'100px'}}></div>
        <div class='d-flex gap-5 justify-content-center flex-wrap'>
            {home.map((el)=>{
                return(
                    <div class='box border rounded-3 p-3 d-grid justify-content-center align-items-center'  style={{width:'300px',height:'150px'}}>
                        <p class='fs-4'>{el.title}</p>
                        <div class=' d-grid justify-content-center '>
                          <p class='option d-flex align-items-center fw-bold gap-1 fs-5' onClick={()=>navigate('/add')}><span class='mt-1'>Add</span><span><FaPlus/></span></p>  
                          <p class='option d-flex align-items-center fw-bold gap-2 fs-5' onClick={()=>navigate('/list')}><span class='mt-1'>List</span><span><FaListAlt /></span></p>  
                        </div>
                    </div>
                )
            })}

        </div>
    </div>
  )
}

export default Home