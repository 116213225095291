import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert'

const LogIn = () => {
    const [show, setShow] = useState(false)
    const [loginDetails,setLoginDetails] = useState({})

    const loginFild =(e)=>{
      const {name,value} = e.target;
      setLoginDetails({...loginDetails,[name]:value})
    }

     


    const navigate = useNavigate()

// console.log('loginDetails',loginDetails);
    const login = () =>{
        if(loginDetails.username && loginDetails.username != ''&&
        loginDetails.password && loginDetails.password != ''
        ){ 
          const config =  {contentType: "application/json"}
            axios.post(`https://app.konfuzio.com/api/v3/auth/`,loginDetails)
            .then((res)=>{ 
                swal( "success",`Status : ${res.status}`,"success")
                const token = res?.data.token 
                sessionStorage.setItem("Api_token", token);
                // console.log(res);
                navigate('/home')
            })
            .catch((err)=>{
                swal( "error",`${err.message}`,"error")
                console.log(err);
            })
        }else{ 
                swal( "Opps !",`fill All fileds`,"error") 
        }
    }
  
  return (
    <div style={{width:'100%',height:'100vh',backgroundImage: 'linear-gradient(to left, #F5893E,#E6D5D7)'}} class='d-flex' > 
        <div class='col-lg-5 col-md-8 col-11 m-auto rounded-3' style={{background:"rgba(0, 0, 0, 0.5)"}}>
            <div class='col-11 m-auto  '> 
                <br />
                <p><i class="bi bi-person-square display-2 "></i></p>
                <input type="text" class='form-control mt-3 text-white' placeholder='username'  style={{background:'transparent'}} name='username' value={loginDetails.username} onChange={loginFild}/>
                <div class='d-flex border mt-3 rounded'> 
                    <input type={show ? 'text' : 'password' } class='form-control text-white' placeholder='password' style={{background:'transparent',border:'none'}}  name='password' value={loginDetails.password} onChange={loginFild} />
                    <div class='d-flex align-items-center px-2 ' style={{height:'35px'}}>
                        {show ? 
                        <p onClick={()=>setShow(!show)}><i class="bi bi-eye-fill fs-5 text-white"></i></p>:
                        <p onClick={()=>setShow(!show)}><i class="bi bi-eye-slash-fill fs-5 text-white"></i></p>
                        }
                    </div>
                     
                </div>
                <p class='text-start mt-1'> Are you new? <span style={{color:'#EEAE89'}} >Register hare</span> </p>
                <div class='text-start my-4'>
                    <button type="button" class="btn btn-dark" onClick={login}>Log-In</button>
                </div>
             
            </div>


        </div>

    </div>
  )
}

export default LogIn