import axios from 'axios'
import React, { useEffect, useState } from 'react'

const category = [
    {
        category :"XXXXXx",
        ai_quality : "XXXXXX",
        active_extration : "XXXXXX",
        extraction_quality : "XXXXXX",
        trainin_docs : "XXXXXX",
        test_docs : "XXXXXX",
    },
    {
        category :"XXXXXx",
        ai_quality : "XXXXXX",
        active_extration : "XXXXXX",
        extraction_quality : "XXXXXX",
        trainin_docs : "XXXXXX",
        test_docs : "XXXXXX",
    },
    {
        category :"XXXXXx",
        ai_quality : "XXXXXX",
        active_extration : "XXXXXX",
        extraction_quality : "XXXXXX",
        trainin_docs : "XXXXXX",
        test_docs : "XXXXXX",
    },
    {
        category :"XXXXXx",
        ai_quality : "XXXXXX",
        active_extration : "XXXXXX",
        extraction_quality : "XXXXXX",
        trainin_docs : "XXXXXX",
        test_docs : "XXXXXX",
    },
    {
        category :"XXXXXx",
        ai_quality : "XXXXXX",
        active_extration : "XXXXXX",
        extraction_quality : "XXXXXX",
        trainin_docs : "XXXXXX",
        test_docs : "XXXXXX",
    },
    {
        category :"XXXXXx",
        ai_quality : "XXXXXX",
        active_extration : "XXXXXX",
        extraction_quality : "XXXXXX",
        trainin_docs : "XXXXXX",
        test_docs : "XXXXXX",
    },
    {
        category :"XXXXXx",
        ai_quality : "XXXXXX",
        active_extration : "XXXXXX",
        extraction_quality : "XXXXXX",
        trainin_docs : "XXXXXX",
        test_docs : "XXXXXX",
    }
]


const List = () => {
  const [data,setData]= useState()
  const [list,setList]= useState()

  const token = sessionStorage.getItem("Api_token")
  // console.log(token);

  useEffect(()=>{
    setData({
      headers:{ 
        "Authorization" :`Token ${token}`,
        "name":"categories",
        "project" :"14262" 
      }}
    )
  },[token])
  console.log(data);

  
    const getList = () =>{
        axios(`https://app.konfuzio.com/api/v3/categories/`,data)
        .then((res)=>{
            // console.log(res?.data?.results);
            setList(res?.data?.results)
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        getList()
    },[data])

    console.log(list);

    const table = () => {
        return (
          <table
            class="table table-bordered text-white my-5">
            <thead style={{ position: "sticky", top: "0" }}>
              <tr>
              <th  class="fw-semibold text-nowrap">
                  Id
                </th>
                <th  class="fw-semibold text-nowrap">
                  CATEGORY
                </th>
                <th  class="fw-semibold text-nowrap">
                  CATEGORIZATION AI QUALITY
                </th>
                <th  class="fw-semibold text-nowrap">
                  ACTIVE EXTRACTION AI
                </th>
                <th  class="fw-semibold text-nowrap">
                  EXTRACTION AI QUALITY
                </th>
                <th  class="fw-semibold text-nowrap">
                  TRAINING DOCS
                </th>
                <th  class="fw-semibold text-nowrap">
                  TEST DOCS
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((el) => {
                return (
                  <tr>
                    <td>{el.id}</td> 
                    <td>
                      <div class='text-start'>
                        <p class='fw-bold'>{el.api_name}</p>
                        <p>{el.description}</p> 
                      </div>
                    </td>
                    <td>{el.ai_model_type}</td>
                    <td>{el.active_ai_model}</td>
                    <td>{el.extraction_quality}</td>
                    <td>{el.trainin_docs}</td>
                    <td>{el.test_docs}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      };
  return (
    <div>  
        <div style={{height:'80px'}}></div>
        <div class='col-11 m-auto' style={{overflowY:'auto'}}>
            {table( )}
        </div>

    </div>
  )
}

export default List