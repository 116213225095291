import React, { useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import './navbar.css'
import { HiMenu } from "react-icons/hi";

const Navbar = () => {
 
  const navigate = useNavigate()

    useEffect(()=>{
        const nav = document.querySelector('.navbarMain');
        let lastScrollY = window.scrollY; 
        window.addEventListener('scroll',()=>{
          if(lastScrollY < window.scrollY){
            nav.classList.add('navbarMain--hidden');
          }else{
            nav.classList.remove('navbarMain--hidden');
          }  
          lastScrollY =window.scrollY 
        })  
    })

  const token = sessionStorage.getItem("Api_token")

  const logout =()=>{
    sessionStorage.clear()
    navigate('/')
  }


  return (
    <div class='navbarMain p-2'>
        <div class='d-flex justify-content-between align-items-center'>
            <div>
                <p class='fs-2 fw-bold text-white mx-5'>API</p>
            </div>
            <div>
                <div class='navPc'>
                  <div class='d-flex gap-4 mx-5'> 
                  {token ? 
                  <>
                    <p class='text-white fs-5' style={{cursor:'pointer'}} onClick={()=>navigate('/home')}>Home</p>
                    <p class='text-white fs-5' style={{cursor:'pointer'}} onClick={logout}>Log-Out</p>
                  </>
                    :
                    <p class='text-white fs-5' style={{cursor:'pointer'}} onClick={()=>logout('/')}>Log-in</p>  
                  }
                  </div>
                </div>
                <div class='navMb'>
                    <button class='btn' data-bs-toggle="offcanvas" href="#offcanvasNav" role="button" aria-controls="offcanvasNav">
                      <HiMenu class='text-white fs-4 fw-bolder'/>
                    </button>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNav" aria-labelledby="offcanvasNavLabel">
                      <div class="offcanvas-header">
                       <div></div>
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                      </div>
                      <div class="offcanvas-body">
                        <div class='d-grid gap-4 mt-5  justify-content-center align-items-center '>
                          {
                            token ? <>
                            <p class='fs-5 fw-bold' style={{cursor:'pointer'}} onClick={()=>navigate('/home')}  data-bs-dismiss="offcanvas" aria-label="Close">Home</p> 
                            <p class='fs-5 fw-bold' style={{cursor:'pointer'}} onClick={()=>navigate('/')}  data-bs-dismiss="offcanvas" aria-label="Close">Log-Out</p>
                            </>:
                            <p class='fs-5 fw-bold' style={{cursor:'pointer'}} onClick={()=>navigate('/')}  data-bs-dismiss="offcanvas" aria-label="Close">Log-in</p>  
                          }
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar