import axios from 'axios'
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'

const Add = () => {
  const token = sessionStorage.getItem("Api_token")
const [data,setData] = useState()
  const [listData,setListData] = useState()
  const [listDetails, setListDetails] = useState({
    // "Authorization" : `Token ${token}`,
    "project" :"14262" , 
  })

  const listInformation = (e)=>{
    const {name,value}= e.target;
    setListDetails({...listDetails,[name]:value})
}

useEffect(()=>{
  setData({
    headers:{ 
      "Authorization" :`Token ${token}`, 
      "project" :"14262" ,  
    }}
  )
},[token,listDetails])


// useEffect(()=>{
//   setListData({
//     headers:listDetails}
//   )
// },[listDetails])

// console.log(listData);




const submitCategory = () =>{
  if(
    listDetails.name && listDetails.name != " " &&
    listDetails.api_name && listDetails.api_name != " " &&
    listDetails.description && listDetails.description != " " && 
    listDetails.active_ai_model && listDetails.active_ai_model != " " 
    // listDetails.ai_model_type && listDetails.ai_model_type != " "  
  ){ 
    axios.post(`https://app.konfuzio.com/api/v3/categories/`,listDetails,data  )
    .then((res)=>{
      console.log(res);
    }).catch((err)=>{
      console.log(err);
    })
  }else{
    swal( "Opps !",`fill All fileds`,"error") 
  }
}
  return (
    <div> 
        <div style={{height:'80px'}}></div>
        <div>
            <p class='fs-1 fw-bold'>ADD CATEGORY</p>

            <div class='text-start m-auto' style={{maxWidth:'600px'}}>

              <div class='mt-3'> 
                <label htmlFor="">Name </label><br />
                <input class='form-control' type="text" placeholder='Name' name='name' value={listDetails?.name} onChange={listInformation}  />
              </div>

              <div class='mt-3'> 
                <label htmlFor="">API name </label> <br />
                <input class='form-control' type="text" placeholder='API name'  name='api_name' value={listDetails?.api_name} onChange={listInformation} />
              </div>

              <div class='mt-3'> 
                <label htmlFor="">Description</label><br />
                <input class='form-control' type="text" placeholder='description'  name='description' value={listDetails?.description} onChange={listInformation} /> 
              </div>

              {/* <div class='mt-3'> 
                <label htmlFor="">Active Ai Model  </label><br />
                <input class='form-control' type="text" placeholder='Active Ai Model  '  name='active_ai_model' value={listDetails?.active_ai_model} onChange={listInformation} /> 
              </div>  */}

              <div class='mt-3'> 
                {/* <label htmlFor="">Ai Model Type</label><br /> */}
                <select class="form-select" aria-label="Default select example"   name='active_ai_model' value={listDetails?.ai_model_type} onChange={listInformation}>
                  <option value="word">Word</option>
                  <option value="character">Character</option> 
                  <option value="sentence">Sentence(Beta)</option>
                  <option value="peragraph">Peragraph(Beta)</option>
                </select>
              </div> 

              <button class='btn btn-dark mt-3 px-4 rounded-5 ' onClick={submitCategory}>SAVE</button>
            
            </div>
             
        </div>

    </div>
  )
}

export default Add